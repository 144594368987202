<script setup lang="ts">
const props = defineProps<{
  text?: string;
  header?: string;
  buttonText?: string;
  callback: () => void;
}>();
</script>

<template>
  <div class="bg-#FF853C/10 b-1 b-#FF853C rd w-full sm:min-w-120 p-3 c-#FF853C">
    <div class="font-bold text-5 mb-1">
      {{
        props.header ? props.header : $t("form.addressValidationWarningHeader")
      }}
    </div>
    <div class="flex flex-col sm:flex-row gap-5 justify-between items-start">
      <p class="text-4.5 mt-3 sm:mt-0">
        {{ props.text ? props.text : $t("form.addressValidationWarningText") }}
      </p>

      <div
        v-if="props.callback"
        class="c-white bg-#FF9251 hover:bg-#FF853C px-3 py-2 rd inline-block cursor-pointer select-none flex-shrink-0 w-full sm:w-auto text-center mt-1 sm:mt-0"
        @click="props.callback"
      >
        {{ props.buttonText ? props.buttonText : $t("form.useAddress") }}
      </div>
    </div>
  </div>
</template>
